import React from "react"
import SEO from "../components/seo"
import SecondaryHeader from "../components/secondary-header"
import ForgotPassword from "./../components/forgot-password"

const ForgotPasswordPage = () => (
  <>
    <SecondaryHeader />
    <SEO title="Forgot Password" />
    <ForgotPassword />
  </>
)

export default ForgotPasswordPage
